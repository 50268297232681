import { track } from '@imtbl/metrics';
import axios from 'axios';
import FormData from 'form-data';
import { BigNumber, constants as constants$1, providers } from 'ethers';
import { ethers, zeroPadValue, toBeHex, keccak256, concat, toUtf8Bytes, TypedDataEncoder, AbiCoder, FetchRequest, JsonRpcProvider, JsonRpcSigner } from 'ethers-v6';
import { MerkleTree } from 'merkletreejs';
import { Environment } from '@imtbl/config';
import { Seaport as Seaport$1 } from '@opensea/seaport-js';

class BaseHttpRequest {
    config;
    constructor(config) {
        this.config = config;
    }
}

class ApiError extends Error {
    url;
    status;
    statusText;
    body;
    request;
    constructor(request, response, message) {
        super(message);
        this.name = 'ApiError';
        this.url = response.url;
        this.status = response.status;
        this.statusText = response.statusText;
        this.body = response.body;
        this.request = request;
    }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
class CancelError extends Error {
    constructor(message) {
        super(message);
        this.name = 'CancelError';
    }
    get isCancelled() {
        return true;
    }
}
class CancelablePromise {
    [Symbol.toStringTag];
    _isResolved;
    _isRejected;
    _isCancelled;
    _cancelHandlers;
    _promise;
    _resolve;
    _reject;
    constructor(executor) {
        this._isResolved = false;
        this._isRejected = false;
        this._isCancelled = false;
        this._cancelHandlers = [];
        this._promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
            const onResolve = (value) => {
                if (this._isResolved || this._isRejected || this._isCancelled) {
                    return;
                }
                this._isResolved = true;
                this._resolve?.(value);
            };
            const onReject = (reason) => {
                if (this._isResolved || this._isRejected || this._isCancelled) {
                    return;
                }
                this._isRejected = true;
                this._reject?.(reason);
            };
            const onCancel = (cancelHandler) => {
                if (this._isResolved || this._isRejected || this._isCancelled) {
                    return;
                }
                this._cancelHandlers.push(cancelHandler);
            };
            Object.defineProperty(onCancel, 'isResolved', {
                get: () => this._isResolved,
            });
            Object.defineProperty(onCancel, 'isRejected', {
                get: () => this._isRejected,
            });
            Object.defineProperty(onCancel, 'isCancelled', {
                get: () => this._isCancelled,
            });
            return executor(onResolve, onReject, onCancel);
        });
    }
    then(onFulfilled, onRejected) {
        return this._promise.then(onFulfilled, onRejected);
    }
    catch(onRejected) {
        return this._promise.catch(onRejected);
    }
    finally(onFinally) {
        return this._promise.finally(onFinally);
    }
    cancel() {
        if (this._isResolved || this._isRejected || this._isCancelled) {
            return;
        }
        this._isCancelled = true;
        if (this._cancelHandlers.length) {
            try {
                for (const cancelHandler of this._cancelHandlers) {
                    cancelHandler();
                }
            }
            catch (error) {
                console.warn('Cancellation threw an error', error);
                return;
            }
        }
        this._cancelHandlers.length = 0;
        this._reject?.(new CancelError('Request aborted'));
    }
    get isCancelled() {
        return this._isCancelled;
    }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
const isDefined = (value) => {
    return value !== undefined && value !== null;
};
const isString = (value) => {
    return typeof value === 'string';
};
const isStringWithValue = (value) => {
    return isString(value) && value !== '';
};
const isBlob = (value) => {
    return (typeof value === 'object' &&
        typeof value.type === 'string' &&
        typeof value.stream === 'function' &&
        typeof value.arrayBuffer === 'function' &&
        typeof value.constructor === 'function' &&
        typeof value.constructor.name === 'string' &&
        /^(Blob|File)$/.test(value.constructor.name) &&
        /^(Blob|File)$/.test(value[Symbol.toStringTag]));
};
const isFormData = (value) => {
    return value instanceof FormData;
};
const isSuccess = (status) => {
    return status >= 200 && status < 300;
};
const base64 = (str) => {
    try {
        return btoa(str);
    }
    catch (err) {
        // @ts-ignore
        return Buffer.from(str).toString('base64');
    }
};
const getQueryString = (params) => {
    const qs = [];
    const append = (key, value) => {
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
    };
    const process = (key, value) => {
        if (isDefined(value)) {
            if (Array.isArray(value)) {
                value.forEach(v => {
                    process(key, v);
                });
            }
            else if (typeof value === 'object') {
                Object.entries(value).forEach(([k, v]) => {
                    process(`${key}[${k}]`, v);
                });
            }
            else {
                append(key, value);
            }
        }
    };
    Object.entries(params).forEach(([key, value]) => {
        process(key, value);
    });
    if (qs.length > 0) {
        return `?${qs.join('&')}`;
    }
    return '';
};
const getUrl = (config, options) => {
    const encoder = config.ENCODE_PATH || encodeURI;
    const path = options.url
        .replace('{api-version}', config.VERSION)
        .replace(/{(.*?)}/g, (substring, group) => {
        if (options.path?.hasOwnProperty(group)) {
            return encoder(String(options.path[group]));
        }
        return substring;
    });
    const url = `${config.BASE}${path}`;
    if (options.query) {
        return `${url}${getQueryString(options.query)}`;
    }
    return url;
};
const getFormData = (options) => {
    if (options.formData) {
        const formData = new FormData();
        const process = (key, value) => {
            if (isString(value) || isBlob(value)) {
                formData.append(key, value);
            }
            else {
                formData.append(key, JSON.stringify(value));
            }
        };
        Object.entries(options.formData)
            .filter(([_, value]) => isDefined(value))
            .forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(v => process(key, v));
            }
            else {
                process(key, value);
            }
        });
        return formData;
    }
    return undefined;
};
const resolve = async (options, resolver) => {
    if (typeof resolver === 'function') {
        return resolver(options);
    }
    return resolver;
};
const getHeaders = async (config, options, formData) => {
    const token = await resolve(options, config.TOKEN);
    const username = await resolve(options, config.USERNAME);
    const password = await resolve(options, config.PASSWORD);
    const additionalHeaders = await resolve(options, config.HEADERS);
    const formHeaders = typeof formData?.getHeaders === 'function' && formData?.getHeaders() || {};
    const headers = Object.entries({
        Accept: 'application/json',
        ...additionalHeaders,
        ...options.headers,
        ...formHeaders,
    })
        .filter(([_, value]) => isDefined(value))
        .reduce((headers, [key, value]) => ({
        ...headers,
        [key]: String(value),
    }), {});
    if (isStringWithValue(token)) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    if (isStringWithValue(username) && isStringWithValue(password)) {
        const credentials = base64(`${username}:${password}`);
        headers['Authorization'] = `Basic ${credentials}`;
    }
    if (options.body) {
        if (options.mediaType) {
            headers['Content-Type'] = options.mediaType;
        }
        else if (isBlob(options.body)) {
            headers['Content-Type'] = options.body.type || 'application/octet-stream';
        }
        else if (isString(options.body)) {
            headers['Content-Type'] = 'text/plain';
        }
        else if (!isFormData(options.body)) {
            headers['Content-Type'] = 'application/json';
        }
    }
    return headers;
};
const getRequestBody = (options) => {
    if (options.body) {
        return options.body;
    }
    return undefined;
};
const sendRequest = async (config, options, url, body, formData, headers, onCancel) => {
    const source = axios.CancelToken.source();
    const requestConfig = {
        url,
        headers,
        data: body ?? formData,
        method: options.method,
        withCredentials: config.WITH_CREDENTIALS,
        cancelToken: source.token,
    };
    onCancel(() => source.cancel('The user aborted a request.'));
    try {
        return await axios.request(requestConfig);
    }
    catch (error) {
        const axiosError = error;
        if (axiosError.response) {
            return axiosError.response;
        }
        throw error;
    }
};
const getResponseHeader = (response, responseHeader) => {
    if (responseHeader) {
        const content = response.headers[responseHeader];
        if (isString(content)) {
            return content;
        }
    }
    return undefined;
};
const getResponseBody = (response) => {
    if (response.status !== 204) {
        return response.data;
    }
    return undefined;
};
const catchErrorCodes = (options, result) => {
    const errors = {
        400: 'Bad Request',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
        500: 'Internal Server Error',
        502: 'Bad Gateway',
        503: 'Service Unavailable',
        ...options.errors,
    };
    const error = errors[result.status];
    if (error) {
        throw new ApiError(options, result, error);
    }
    if (!result.ok) {
        throw new ApiError(options, result, 'Generic Error');
    }
};
/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param options The request options from the service
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
const request = (config, options) => {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
        try {
            const url = getUrl(config, options);
            const formData = getFormData(options);
            const body = getRequestBody(options);
            const headers = await getHeaders(config, options, formData);
            if (!onCancel.isCancelled) {
                const response = await sendRequest(config, options, url, body, formData, headers, onCancel);
                const responseBody = getResponseBody(response);
                const responseHeader = getResponseHeader(response, options.responseHeader);
                const result = {
                    url,
                    ok: isSuccess(response.status),
                    status: response.status,
                    statusText: response.statusText,
                    body: responseHeader ?? responseBody,
                };
                catchErrorCodes(options, result);
                resolve(result.body);
            }
        }
        catch (error) {
            reject(error);
        }
    });
};

class AxiosHttpRequest extends BaseHttpRequest {
    constructor(config) {
        super(config);
    }
    /**
     * Request method
     * @param options The request options from the service
     * @returns CancelablePromise<T>
     * @throws ApiError
     */
    request(options) {
        return request(this.config, options);
    }
}

class OrdersService {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Cancel one or more orders
     * Cancel one or more orders
     * @returns CancelOrdersResult Orders cancellation response.
     * @throws ApiError
     */
    cancelOrders({ chainName, requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/chains/{chain_name}/orders/cancel',
            path: {
                'chain_name': chainName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request (400)`,
                401: `Unauthorised Request (401)`,
                404: `The specified resource was not found (404)`,
                429: `Too Many Requests (429)`,
                500: `Internal Server Error (500)`,
                501: `Not Implemented Error (501)`,
            },
        });
    }
    /**
     * List all listings
     * List all listings
     * @returns ListListingsResult OK response.
     * @throws ApiError
     */
    listListings({ chainName, status, sellItemContractAddress, buyItemType, buyItemContractAddress, accountAddress, sellItemMetadataId, sellItemTokenId, fromUpdatedAt, pageSize, sortBy, sortDirection, pageCursor, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/chains/{chain_name}/orders/listings',
            path: {
                'chain_name': chainName,
            },
            query: {
                'status': status,
                'sell_item_contract_address': sellItemContractAddress,
                'buy_item_type': buyItemType,
                'buy_item_contract_address': buyItemContractAddress,
                'account_address': accountAddress,
                'sell_item_metadata_id': sellItemMetadataId,
                'sell_item_token_id': sellItemTokenId,
                'from_updated_at': fromUpdatedAt,
                'page_size': pageSize,
                'sort_by': sortBy,
                'sort_direction': sortDirection,
                'page_cursor': pageCursor,
            },
            errors: {
                400: `Bad Request (400)`,
                404: `The specified resource was not found (404)`,
                500: `Internal Server Error (500)`,
            },
        });
    }
    /**
     * Create a listing
     * Create a listing
     * @returns ListingResult Created response.
     * @throws ApiError
     */
    createListing({ chainName, requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/chains/{chain_name}/orders/listings',
            path: {
                'chain_name': chainName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request (400)`,
                404: `The specified resource was not found (404)`,
                500: `Internal Server Error (500)`,
            },
        });
    }
    /**
     * Get a single listing by ID
     * Get a single listing by ID
     * @returns ListingResult OK response.
     * @throws ApiError
     */
    getListing({ chainName, listingId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/chains/{chain_name}/orders/listings/{listing_id}',
            path: {
                'chain_name': chainName,
                'listing_id': listingId,
            },
            errors: {
                400: `Bad Request (400)`,
                404: `The specified resource was not found (404)`,
                500: `Internal Server Error (500)`,
            },
        });
    }
    /**
     * Retrieve fulfillment data for orders
     * Retrieve signed fulfillment data based on the list of order IDs and corresponding fees.
     * @returns any Successful response
     * @throws ApiError
     */
    fulfillmentData({ chainName, requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/chains/{chain_name}/orders/fulfillment-data',
            path: {
                'chain_name': chainName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request (400)`,
                404: `The specified resource was not found (404)`,
                500: `Internal Server Error (500)`,
            },
        });
    }
    /**
     * List all trades
     * List all trades
     * @returns ListTradeResult OK response.
     * @throws ApiError
     */
    listTrades({ chainName, accountAddress, sellItemContractAddress, fromIndexedAt, pageSize, sortBy, sortDirection, pageCursor, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/chains/{chain_name}/trades',
            path: {
                'chain_name': chainName,
            },
            query: {
                'account_address': accountAddress,
                'sell_item_contract_address': sellItemContractAddress,
                'from_indexed_at': fromIndexedAt,
                'page_size': pageSize,
                'sort_by': sortBy,
                'sort_direction': sortDirection,
                'page_cursor': pageCursor,
            },
            errors: {
                400: `Bad Request (400)`,
                404: `The specified resource was not found (404)`,
                500: `Internal Server Error (500)`,
            },
        });
    }
    /**
     * Get a single trade by ID
     * Get a single trade by ID
     * @returns TradeResult OK response.
     * @throws ApiError
     */
    getTrade({ chainName, tradeId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/chains/{chain_name}/trades/{trade_id}',
            path: {
                'chain_name': chainName,
                'trade_id': tradeId,
            },
            errors: {
                400: `Bad Request (400)`,
                404: `The specified resource was not found (404)`,
                500: `Internal Server Error (500)`,
            },
        });
    }
}

class OrderBookClient {
    orders;
    request;
    constructor(config, HttpRequest = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://api.immutable.com',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.orders = new OrdersService(this.request);
    }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
var CancelledOrderStatus;
(function (CancelledOrderStatus) {
    (function (cancellation_type) {
        cancellation_type["ON_CHAIN"] = "ON_CHAIN";
        cancellation_type["OFF_CHAIN"] = "OFF_CHAIN";
        cancellation_type["UNDERFUNDED"] = "UNDERFUNDED";
    })(CancelledOrderStatus.cancellation_type || (CancelledOrderStatus.cancellation_type = {}));
})(CancelledOrderStatus || (CancelledOrderStatus = {}));

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
var FailedOrderCancellation;
(function (FailedOrderCancellation) {
    (function (reason_code) {
        reason_code["FILLED"] = "FILLED";
    })(FailedOrderCancellation.reason_code || (FailedOrderCancellation.reason_code = {}));
})(FailedOrderCancellation || (FailedOrderCancellation = {}));

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
var Fee;
(function (Fee) {
    (function (type) {
        type["ROYALTY"] = "ROYALTY";
        type["MAKER_ECOSYSTEM"] = "MAKER_ECOSYSTEM";
        type["TAKER_ECOSYSTEM"] = "TAKER_ECOSYSTEM";
        type["PROTOCOL"] = "PROTOCOL";
    })(Fee.type || (Fee.type = {}));
})(Fee || (Fee = {}));

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
var Order;
(function (Order) {
    (function (type) {
        type["LISTING"] = "LISTING";
    })(Order.type || (Order.type = {}));
})(Order || (Order = {}));

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * The Order status
 */
var OrderStatusName;
(function (OrderStatusName) {
    OrderStatusName["PENDING"] = "PENDING";
    OrderStatusName["ACTIVE"] = "ACTIVE";
    OrderStatusName["INACTIVE"] = "INACTIVE";
    OrderStatusName["FILLED"] = "FILLED";
    OrderStatusName["EXPIRED"] = "EXPIRED";
    OrderStatusName["CANCELLED"] = "CANCELLED";
})(OrderStatusName || (OrderStatusName = {}));

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
var ProtocolData;
(function (ProtocolData) {
    (function (order_type) {
        order_type["FULL_RESTRICTED"] = "FULL_RESTRICTED";
        order_type["PARTIAL_RESTRICTED"] = "PARTIAL_RESTRICTED";
    })(ProtocolData.order_type || (ProtocolData.order_type = {}));
})(ProtocolData || (ProtocolData = {}));

var FeeType;
(function (FeeType) {
    FeeType["MAKER_ECOSYSTEM"] = "MAKER_ECOSYSTEM";
    FeeType["TAKER_ECOSYSTEM"] = "TAKER_ECOSYSTEM";
    FeeType["PROTOCOL"] = "PROTOCOL";
    FeeType["ROYALTY"] = "ROYALTY";
})(FeeType || (FeeType = {}));
var TransactionPurpose;
(function (TransactionPurpose) {
    TransactionPurpose["APPROVAL"] = "APPROVAL";
    TransactionPurpose["FULFILL_ORDER"] = "FULFILL_ORDER";
    TransactionPurpose["CANCEL"] = "CANCEL";
})(TransactionPurpose || (TransactionPurpose = {}));
var SignablePurpose;
(function (SignablePurpose) {
    SignablePurpose["CREATE_LISTING"] = "CREATE_LISTING";
    SignablePurpose["OFF_CHAIN_CANCELLATION"] = "OFF_CHAIN_CANCELLATION";
})(SignablePurpose || (SignablePurpose = {}));
var ActionType;
(function (ActionType) {
    ActionType["TRANSACTION"] = "TRANSACTION";
    ActionType["SIGNABLE"] = "SIGNABLE";
})(ActionType || (ActionType = {}));

function mapFromOpenApiOrder(order) {
    const buyItems = order.buy.map((item) => {
        if (item.type === 'ERC20') {
            return {
                type: 'ERC20',
                contractAddress: item.contract_address,
                amount: item.amount,
            };
        }
        if (item.type === 'NATIVE') {
            return {
                type: 'NATIVE',
                amount: item.amount,
            };
        }
        throw new Error('Buy items must be either ERC20 or NATIVE');
    });
    const sellItems = order.sell.map((item) => {
        if (item.type === 'ERC721') {
            return {
                type: 'ERC721',
                contractAddress: item.contract_address,
                tokenId: item.token_id,
            };
        }
        if (item.type === 'ERC1155') {
            return {
                type: 'ERC1155',
                contractAddress: item.contract_address,
                tokenId: item.token_id,
                amount: item.amount,
            };
        }
        throw new Error('Sell items must ERC721 or ERC1155');
    });
    return {
        id: order.id,
        type: order.type,
        accountAddress: order.account_address,
        buy: buyItems,
        sell: sellItems,
        fees: order.fees.map((fee) => ({
            amount: fee.amount,
            recipientAddress: fee.recipient_address,
            type: fee.type,
        })),
        fillStatus: order.fill_status,
        chain: order.chain,
        createdAt: order.created_at,
        endAt: order.end_at,
        orderHash: order.order_hash,
        protocolData: {
            orderType: order.protocol_data.order_type,
            counter: order.protocol_data.counter,
            seaportAddress: order.protocol_data.seaport_address,
            seaportVersion: order.protocol_data.seaport_version,
            zoneAddress: order.protocol_data.zone_address,
        },
        salt: order.salt,
        signature: order.signature,
        startAt: order.start_at,
        status: order.status,
        updatedAt: order.updated_at,
    };
}
function mapFromOpenApiTrade(trade) {
    const buyItems = trade.buy.map((item) => {
        if (item.type === 'ERC20') {
            return {
                type: 'ERC20',
                contractAddress: item.contract_address,
                amount: item.amount,
            };
        }
        if (item.type === 'NATIVE') {
            return {
                type: 'NATIVE',
                amount: item.amount,
            };
        }
        throw new Error('Buy items must be either ERC20 or NATIVE');
    });
    const sellItems = trade.sell.map((item) => {
        if (item.type === 'ERC721') {
            return {
                type: 'ERC721',
                contractAddress: item.contract_address,
                tokenId: item.token_id,
            };
        }
        if (item.type === 'ERC1155') {
            return {
                type: 'ERC1155',
                contractAddress: item.contract_address,
                tokenId: item.token_id,
                amount: item.amount,
            };
        }
        throw new Error('Sell items must ERC721');
    });
    return {
        id: trade.id,
        orderId: trade.order_id,
        buy: buyItems,
        sell: sellItems,
        buyerFees: trade.buyer_fees.map((fee) => ({
            amount: fee.amount,
            recipientAddress: fee.recipient_address,
            type: fee.type,
        })),
        chain: trade.chain,
        indexedAt: trade.indexed_at,
        blockchainMetadata: {
            blockNumber: trade.blockchain_metadata.block_number,
            logIndex: trade.blockchain_metadata.log_index,
            transactionHash: trade.blockchain_metadata.transaction_hash,
            transactionIndex: trade.blockchain_metadata.transaction_index,
        },
        buyerAddress: trade.buyer_address,
        makerAddress: trade.maker_address,
        sellerAddress: trade.seller_address,
        takerAddress: trade.taker_address,
    };
}
function mapFromOpenApiPage(page) {
    return {
        nextCursor: page.next_cursor,
        previousCursor: page.previous_cursor,
    };
}

/* eslint-disable */
// TODO: Resolve these from seaport-js.
// There is some bundling issue that is preventing this from working
const SEAPORT_CONTRACT_NAME = 'ImmutableSeaport';
// export const SEAPORT_CONTRACT_VERSION_V1_4 = '1.4';
const SEAPORT_CONTRACT_VERSION_V1_5 = '1.5';
const EIP_712_ORDER_TYPE = {
    OrderComponents: [
        { name: 'offerer', type: 'address' },
        { name: 'zone', type: 'address' },
        { name: 'offer', type: 'OfferItem[]' },
        { name: 'consideration', type: 'ConsiderationItem[]' },
        { name: 'orderType', type: 'uint8' },
        { name: 'startTime', type: 'uint256' },
        { name: 'endTime', type: 'uint256' },
        { name: 'zoneHash', type: 'bytes32' },
        { name: 'salt', type: 'uint256' },
        { name: 'conduitKey', type: 'bytes32' },
        { name: 'counter', type: 'uint256' },
    ],
    OfferItem: [
        { name: 'itemType', type: 'uint8' },
        { name: 'token', type: 'address' },
        { name: 'identifierOrCriteria', type: 'uint256' },
        { name: 'startAmount', type: 'uint256' },
        { name: 'endAmount', type: 'uint256' },
    ],
    ConsiderationItem: [
        { name: 'itemType', type: 'uint8' },
        { name: 'token', type: 'address' },
        { name: 'identifierOrCriteria', type: 'uint256' },
        { name: 'startAmount', type: 'uint256' },
        { name: 'endAmount', type: 'uint256' },
        { name: 'recipient', type: 'address' },
    ],
};
var OrderType;
(function (OrderType) {
    OrderType[OrderType["FULL_OPEN"] = 0] = "FULL_OPEN";
    OrderType[OrderType["PARTIAL_OPEN"] = 1] = "PARTIAL_OPEN";
    OrderType[OrderType["FULL_RESTRICTED"] = 2] = "FULL_RESTRICTED";
    OrderType[OrderType["PARTIAL_RESTRICTED"] = 3] = "PARTIAL_RESTRICTED";
})(OrderType || (OrderType = {}));
var ItemType;
(function (ItemType) {
    ItemType[ItemType["NATIVE"] = 0] = "NATIVE";
    ItemType[ItemType["ERC20"] = 1] = "ERC20";
    ItemType[ItemType["ERC721"] = 2] = "ERC721";
    ItemType[ItemType["ERC1155"] = 3] = "ERC1155";
    ItemType[ItemType["ERC721_WITH_CRITERIA"] = 4] = "ERC721_WITH_CRITERIA";
    ItemType[ItemType["ERC1155_WITH_CRITERIA"] = 5] = "ERC1155_WITH_CRITERIA";
})(ItemType || (ItemType = {}));
var Side;
(function (Side) {
    Side[Side["OFFER"] = 0] = "OFFER";
    Side[Side["CONSIDERATION"] = 1] = "CONSIDERATION";
})(Side || (Side = {}));
var BasicOrderRouteType;
(function (BasicOrderRouteType) {
    BasicOrderRouteType[BasicOrderRouteType["ETH_TO_ERC721"] = 0] = "ETH_TO_ERC721";
    BasicOrderRouteType[BasicOrderRouteType["ETH_TO_ERC1155"] = 1] = "ETH_TO_ERC1155";
    BasicOrderRouteType[BasicOrderRouteType["ERC20_TO_ERC721"] = 2] = "ERC20_TO_ERC721";
    BasicOrderRouteType[BasicOrderRouteType["ERC20_TO_ERC1155"] = 3] = "ERC20_TO_ERC1155";
    BasicOrderRouteType[BasicOrderRouteType["ERC721_TO_ERC20"] = 4] = "ERC721_TO_ERC20";
    BasicOrderRouteType[BasicOrderRouteType["ERC1155_TO_ERC20"] = 5] = "ERC1155_TO_ERC20";
})(BasicOrderRouteType || (BasicOrderRouteType = {}));

const baseDefaults = {
    integer: 0,
    address: ethers.zeroPadValue('0x', 20),
    bool: false,
    bytes: '0x',
    string: '',
};
const isNullish = (value) => {
    if (value === undefined)
        return false;
    return (value !== undefined
        && value !== null
        && ((['string', 'number'].includes(typeof value) && BigInt(value) === 0n)
            || (Array.isArray(value) && value.every(isNullish))
            || (typeof value === 'object' && Object.values(value).every(isNullish))
            || (typeof value === 'boolean' && value === false)));
};
function getDefaultForBaseType(type) {
    // bytesXX
    const [, width] = type.match(/^bytes(\d+)$/) ?? [];
    // eslint-disable-next-line radix
    if (width)
        return zeroPadValue('0x', parseInt(width));
    // eslint-disable-next-line no-param-reassign
    if (type.match(/^(u?)int(\d*)$/))
        type = 'integer';
    return baseDefaults[type];
}
class DefaultGetter {
    types;
    defaultValues = {};
    constructor(types) {
        this.types = types;
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const name in types) {
            const defaultValue = this.getDefaultValue(name);
            this.defaultValues[name] = defaultValue;
            if (!isNullish(defaultValue)) {
                throw new Error(`Got non-empty value for type ${name} in default generator: ${defaultValue}`);
            }
        }
    }
    static from(types, type) {
        const { defaultValues } = new DefaultGetter(types);
        if (type)
            return defaultValues[type];
        return defaultValues;
    }
    /* eslint-enable no-dupe-class-members */
    getDefaultValue(type) {
        if (this.defaultValues[type])
            return this.defaultValues[type];
        // Basic type (address, bool, uint256, etc)
        const basic = getDefaultForBaseType(type);
        if (basic !== undefined)
            return basic;
        // Array
        const match = type.match(/^(.*)(\x5b(\d*)\x5d)$/);
        if (match) {
            const subtype = match[1];
            // eslint-disable-next-line radix
            const length = parseInt(match[3]);
            if (length > 0) {
                const baseValue = this.getDefaultValue(subtype);
                return Array(length).fill(baseValue);
            }
            return [];
        }
        // Struct
        const fields = this.types[type];
        if (fields) {
            return fields.reduce(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            (obj, { name, type }) => ({
                ...obj,
                [name]: this.getDefaultValue(type),
            }), {});
        }
        throw new Error(`unknown type: ${type}`);
    }
}

const makeArray = (len, getValue) => Array(len)
    .fill(0)
    .map((_, i) => getValue(i));
// eslint-disable-next-line max-len
const chunk = (array, size) => makeArray(Math.ceil(array.length / size), (i) => array.slice(i * size, (i + 1) * size));
const bufferToHex = (buf) => toBeHex(buf.toString('hex'));
const hexToBuffer = (value) => Buffer.from(value.slice(2), 'hex');
const bufferKeccak = (value) => hexToBuffer(keccak256(value));
const hashConcat = (arr) => bufferKeccak(concat(arr));
const fillArray = (arr, length, value) => {
    if (length > arr.length)
        arr.push(...Array(length - arr.length).fill(value));
    return arr;
};
const getRoot = (elements, hashLeaves = true) => {
    if (elements.length === 0)
        throw new Error('empty tree');
    const leaves = elements.map((e) => {
        const leaf = Buffer.isBuffer(e) ? e : hexToBuffer(e);
        return hashLeaves ? bufferKeccak(leaf) : leaf;
    });
    const layers = [leaves];
    // Get next layer until we reach the root
    while (layers[layers.length - 1].length > 1) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        layers.push(getNextLayer(layers[layers.length - 1]));
    }
    return layers[layers.length - 1][0];
};
const getNextLayer = (elements) => chunk(elements, 2).map(hashConcat);

// eslint-disable-next-line max-len
const getTree = (leaves, defaultLeafHash) => new MerkleTree(leaves.map(hexToBuffer), bufferKeccak, {
    complete: true,
    sort: false,
    hashLeaves: false,
    fillDefaultHash: hexToBuffer(defaultLeafHash),
});
const encodeProof = (key, proof, signature = `0x${'ff'.repeat(64)}`) => concat([
    signature,
    `0x${key.toString(16).padStart(6, '0')}`,
    AbiCoder.defaultAbiCoder().encode([`uint256[${proof.length}]`], [proof]),
]);
class Eip712MerkleTree {
    types;
    rootType;
    leafType;
    elements;
    depth;
    tree;
    leafHasher;
    defaultNode;
    defaultLeaf;
    encoder;
    get completedSize() {
        return 2 ** this.depth;
    }
    /** Returns the array of elements in the tree, padded to the complete size with empty items. */
    getCompleteElements() {
        const { elements } = this;
        return fillArray([...elements], this.completedSize, this.defaultNode);
    }
    // eslint-disable-next-line max-len
    /** Returns the array of leaf nodes in the tree, padded to the complete size with default hashes. */
    getCompleteLeaves() {
        const leaves = this.elements.map(this.leafHasher);
        return fillArray([...leaves], this.completedSize, this.defaultLeaf);
    }
    get root() {
        return this.tree.getHexRoot();
    }
    getProof(i) {
        const leaves = this.getCompleteLeaves();
        const leaf = leaves[i];
        const proof = this.tree.getHexProof(leaf, i);
        const root = this.tree.getHexRoot();
        return { leaf, proof, root };
    }
    getEncodedProofAndSignature(i, signature) {
        const { proof } = this.getProof(i);
        return encodeProof(i, proof, signature);
    }
    getDataToSign() {
        let layer = this.getCompleteElements();
        while (layer.length > 2) {
            layer = chunk(layer, 2);
        }
        return layer;
    }
    add(element) {
        this.elements.push(element);
    }
    getBulkOrderHash() {
        const structHash = this.encoder.hashStruct('BulkOrder', {
            tree: this.getDataToSign(),
        });
        const leaves = this.getCompleteLeaves().map(hexToBuffer);
        const rootHash = bufferToHex(getRoot(leaves, false));
        const typeHash = keccak256(toUtf8Bytes(this.encoder.types.BulkOrder[0].type));
        const bulkOrderHash = keccak256(concat([typeHash, rootHash]));
        if (bulkOrderHash !== structHash) {
            throw new Error('expected derived bulk order hash to match');
        }
        return structHash;
    }
    constructor(types, rootType, leafType, elements, depth) {
        this.types = types;
        this.rootType = rootType;
        this.leafType = leafType;
        this.elements = elements;
        this.depth = depth;
        const encoder = TypedDataEncoder.from(types);
        this.encoder = encoder;
        this.leafHasher = (leaf) => encoder.hashStruct(leafType, leaf);
        this.defaultNode = DefaultGetter.from(types, leafType);
        this.defaultLeaf = this.leafHasher(this.defaultNode);
        this.tree = getTree(this.getCompleteLeaves(), this.defaultLeaf);
    }
}

function getBulkOrderTypes(height) {
    return {
        ...EIP_712_ORDER_TYPE,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        BulkOrder: [{ name: 'tree', type: `OrderComponents${'[2]'.repeat(height)}` }],
    };
}
function getBulkOrderTreeHeight(length) {
    return Math.max(Math.ceil(Math.log2(length)), 1);
}
function getBulkOrderTree(orderComponents, startIndex = 0, height = getBulkOrderTreeHeight(orderComponents.length + startIndex)) {
    const types = getBulkOrderTypes(height);
    const defaultNode = DefaultGetter.from(types, 'OrderComponents');
    let elements = [...orderComponents];
    if (startIndex > 0) {
        elements = [
            ...fillArray([], startIndex, defaultNode),
            ...orderComponents,
        ];
    }
    const tree = new Eip712MerkleTree(types, 'BulkOrder', 'OrderComponents', elements, height);
    return tree;
}

function getOrderComponentsFromMessage(orderMessage) {
    const data = JSON.parse(orderMessage);
    const orderComponents = data.message;
    orderComponents.salt = BigNumber.from(orderComponents.salt).toHexString();
    return orderComponents;
}
function getBulkOrderComponentsFromMessage(orderMessage) {
    const data = JSON.parse(orderMessage);
    const orderComponents = data.message.tree.flat(Infinity)
        // Filter off the zero nodes in the tree. The will get rebuilt bu `getBulkOrderTree`
        // when creating the listings
        .filter((o) => o.offerer !== '0x0000000000000000000000000000000000000000');
    // eslint-disable-next-line no-restricted-syntax
    for (const orderComponent of orderComponents) {
        orderComponent.salt = BigNumber.from(orderComponent.salt).toHexString();
    }
    return { components: orderComponents, types: data.types, value: data.message };
}
function getBulkSeaportOrderSignatures(signature, orderComponents) {
    const tree = getBulkOrderTree(orderComponents);
    return orderComponents.map((_, i) => tree.getEncodedProofAndSignature(i, signature));
}

// Add 20% more gas than estimate to prevent out of gas errors
// This can always be overwritten by the user signing the transaction
function prepareTransaction(transactionMethods, 
// chainId is required for EIP155
chainId, callerAddress) {
    return async () => {
        const v6ContractTransaction = await transactionMethods.buildTransaction();
        const v5PopulatedTransaction = {
            to: v6ContractTransaction.to,
            from: callerAddress,
            type: v6ContractTransaction.type,
            maxFeePerGas: v6ContractTransaction.maxFeePerGas
                ? BigNumber.from(v6ContractTransaction.maxFeePerGas)
                : undefined,
            maxPriorityFeePerGas: v6ContractTransaction.maxPriorityFeePerGas
                ? BigNumber.from(v6ContractTransaction.maxPriorityFeePerGas)
                : undefined,
            value: v6ContractTransaction.value ? BigNumber.from(v6ContractTransaction.value) : undefined,
            data: v6ContractTransaction.data,
            nonce: v6ContractTransaction.nonce,
            chainId,
        };
        v5PopulatedTransaction.gasLimit = BigNumber.from(await transactionMethods.estimateGas());
        v5PopulatedTransaction.gasLimit = v5PopulatedTransaction.gasLimit
            .add(v5PopulatedTransaction.gasLimit.div(5));
        return v5PopulatedTransaction;
    };
}

function mapImmutableOrderToSeaportOrderComponents(order) {
    const considerationItems = order.buy.map((buyItem) => {
        switch (buyItem.type) {
            case 'NATIVE':
                return {
                    startAmount: buyItem.amount,
                    endAmount: buyItem.amount,
                    itemType: ItemType.NATIVE,
                    recipient: order.account_address,
                    token: constants$1.AddressZero,
                    identifierOrCriteria: '0',
                };
            case 'ERC20':
                return {
                    startAmount: buyItem.amount,
                    endAmount: buyItem.amount,
                    itemType: ItemType.ERC20,
                    recipient: order.account_address,
                    token: buyItem.contract_address || constants$1.AddressZero,
                    identifierOrCriteria: '0',
                };
            default: // ERC721
                return {
                    startAmount: '1',
                    endAmount: '1',
                    itemType: ItemType.ERC721,
                    recipient: order.account_address,
                    token: buyItem.contract_address || constants$1.AddressZero,
                    identifierOrCriteria: '0',
                };
        }
    });
    const fees = order.fees.map((fee) => ({
        amount: fee.amount,
        itemType: order.buy[0].type === 'ERC20' ? ItemType.ERC20 : ItemType.NATIVE,
        recipient: fee.recipient_address,
        token: order.buy[0].type === 'ERC20'
            ? order.buy[0].contract_address
            : constants$1.AddressZero,
        identifierOrCriteria: '0',
    }));
    return {
        orderComponents: {
            conduitKey: constants$1.HashZero,
            consideration: [...considerationItems],
            offer: order.sell.map((sellItem) => {
                let tokenItem;
                switch (sellItem.type) {
                    case 'ERC1155':
                        tokenItem = sellItem;
                        return {
                            startAmount: tokenItem.amount,
                            endAmount: tokenItem.amount,
                            itemType: ItemType.ERC1155,
                            recipient: order.account_address,
                            token: tokenItem.contract_address,
                            identifierOrCriteria: tokenItem.token_id,
                        };
                    default: // ERC721
                        tokenItem = sellItem;
                        return {
                            startAmount: '1',
                            endAmount: '1',
                            itemType: ItemType.ERC721,
                            recipient: order.account_address,
                            token: tokenItem.contract_address,
                            identifierOrCriteria: tokenItem.token_id,
                        };
                }
            }),
            counter: order.protocol_data.counter,
            endTime: Math.round(new Date(order.end_at).getTime() / 1000).toString(),
            startTime: Math.round(new Date(order.start_at).getTime() / 1000).toString(),
            salt: order.salt,
            offerer: order.account_address,
            zone: order.protocol_data.zone_address,
            // this should be the fee exclusive number of items the user signed for
            totalOriginalConsiderationItems: considerationItems.length,
            orderType: order.sell[0].type === 'ERC1155' ? OrderType.PARTIAL_RESTRICTED : OrderType.FULL_RESTRICTED,
            zoneHash: constants$1.HashZero,
        },
        tips: fees,
    };
}

class Seaport {
    seaportLibFactory;
    provider;
    seaportContractAddress;
    zoneContractAddress;
    rateLimitingKey;
    constructor(seaportLibFactory, provider, seaportContractAddress, zoneContractAddress, rateLimitingKey) {
        this.seaportLibFactory = seaportLibFactory;
        this.provider = provider;
        this.seaportContractAddress = seaportContractAddress;
        this.zoneContractAddress = zoneContractAddress;
        this.rateLimitingKey = rateLimitingKey;
    }
    async prepareBulkSeaportOrders(offerer, orderInputs) {
        const { actions: seaportActions } = await this.createSeaportOrders(offerer, orderInputs);
        const approvalActions = seaportActions.filter((action) => action.type === 'approval');
        const network = await this.provider.getNetwork();
        const listingActions = approvalActions.map((approvalAction) => ({
            type: ActionType.TRANSACTION,
            purpose: TransactionPurpose.APPROVAL,
            buildTransaction: prepareTransaction(approvalAction.transactionMethods, network.chainId, offerer),
        }));
        const createAction = seaportActions.find((action) => action.type === 'createBulk');
        if (!createAction) {
            throw new Error('No create bulk order action found');
        }
        const orderMessageToSign = await createAction.getMessageToSign();
        const { components, types, value } = getBulkOrderComponentsFromMessage(orderMessageToSign);
        listingActions.push({
            type: ActionType.SIGNABLE,
            purpose: SignablePurpose.CREATE_LISTING,
            message: await this.getTypedDataFromBulkOrderComponents(types, value),
        });
        return {
            actions: listingActions,
            preparedListings: components.map((orderComponent) => ({
                orderComponents: orderComponent,
                orderHash: this.getSeaportLib().getOrderHash(orderComponent),
            })),
        };
    }
    async prepareSeaportOrder(offerer, listingItem, considerationItem, orderStart, orderExpiry) {
        const { actions: seaportActions } = await this.createSeaportOrder(offerer, listingItem, considerationItem, orderStart, orderExpiry);
        const listingActions = [];
        const approvalAction = seaportActions.find((action) => action.type === 'approval');
        if (approvalAction) {
            listingActions.push({
                type: ActionType.TRANSACTION,
                purpose: TransactionPurpose.APPROVAL,
                buildTransaction: prepareTransaction(approvalAction.transactionMethods, (await this.provider.getNetwork()).chainId, offerer),
            });
        }
        const createAction = seaportActions.find((action) => action.type === 'create');
        if (!createAction) {
            throw new Error('No create order action found');
        }
        const orderMessageToSign = await createAction.getMessageToSign();
        const orderComponents = getOrderComponentsFromMessage(orderMessageToSign);
        listingActions.push({
            type: ActionType.SIGNABLE,
            purpose: SignablePurpose.CREATE_LISTING,
            message: await this.getTypedDataFromOrderComponents(orderComponents),
        });
        return {
            actions: listingActions,
            orderComponents,
            orderHash: this.getSeaportLib().getOrderHash(orderComponents),
        };
    }
    async fulfillOrder(order, account, extraData, unitsToFill) {
        const { orderComponents, tips } = mapImmutableOrderToSeaportOrderComponents(order);
        const seaportLib = this.getSeaportLib(order);
        const { actions: seaportActions } = await seaportLib.fulfillOrders({
            accountAddress: account,
            fulfillOrderDetails: [
                {
                    order: {
                        parameters: orderComponents,
                        signature: order.signature,
                    },
                    unitsToFill,
                    extraData,
                    tips,
                },
            ],
        });
        const fulfillmentActions = [];
        const approvalAction = seaportActions.find((action) => action.type === 'approval');
        if (approvalAction) {
            fulfillmentActions.push({
                type: ActionType.TRANSACTION,
                buildTransaction: prepareTransaction(approvalAction.transactionMethods, (await this.provider.getNetwork()).chainId, account),
                purpose: TransactionPurpose.APPROVAL,
            });
        }
        const fulfilOrderAction = seaportActions.find((action) => action.type === 'exchange');
        if (!fulfilOrderAction) {
            throw new Error('No exchange action found');
        }
        fulfillmentActions.push({
            type: ActionType.TRANSACTION,
            buildTransaction: prepareTransaction(fulfilOrderAction.transactionMethods, (await this.provider.getNetwork()).chainId, account),
            purpose: TransactionPurpose.FULFILL_ORDER,
        });
        return {
            actions: fulfillmentActions,
            expiration: Seaport.getExpirationISOTimeFromExtraData(extraData),
            order: mapFromOpenApiOrder(order),
        };
    }
    async fulfillBulkOrders(fulfillingOrders, account) {
        const fulfillOrderDetails = fulfillingOrders.map((o) => {
            const { orderComponents, tips } = mapImmutableOrderToSeaportOrderComponents(o.order);
            return {
                order: {
                    parameters: orderComponents,
                    signature: o.order.signature,
                },
                unitsToFill: o.unitsToFill,
                extraData: o.extraData,
                tips,
            };
        });
        const { actions: seaportActions } = await this.getSeaportLib().fulfillOrders({
            fulfillOrderDetails,
            accountAddress: account,
        });
        const fulfillmentActions = [];
        const approvalAction = seaportActions.find((action) => action.type === 'approval');
        if (approvalAction) {
            fulfillmentActions.push({
                type: ActionType.TRANSACTION,
                buildTransaction: prepareTransaction(approvalAction.transactionMethods, (await this.provider.getNetwork()).chainId, account),
                purpose: TransactionPurpose.APPROVAL,
            });
        }
        const fulfilOrderAction = seaportActions.find((action) => action.type === 'exchange');
        if (!fulfilOrderAction) {
            throw new Error('No exchange action found');
        }
        fulfillmentActions.push({
            type: ActionType.TRANSACTION,
            buildTransaction: prepareTransaction(fulfilOrderAction.transactionMethods, (await this.provider.getNetwork()).chainId, account),
            purpose: TransactionPurpose.FULFILL_ORDER,
        });
        return {
            actions: fulfillmentActions,
            // return the shortest expiration out of all extraData - they should be very close
            expiration: fulfillOrderDetails
                .map((d) => Seaport.getExpirationISOTimeFromExtraData(d.extraData))
                .reduce((p, c) => (new Date(p) < new Date(c) ? p : c)),
        };
    }
    async cancelOrders(orders, account) {
        const orderComponents = orders.map((order) => mapImmutableOrderToSeaportOrderComponents(order).orderComponents);
        const seaportLib = this.getSeaportLib(orders[0]);
        const cancellationTransaction = await seaportLib.cancelOrders(orderComponents, account);
        return {
            type: ActionType.TRANSACTION,
            buildTransaction: prepareTransaction(cancellationTransaction, (await this.provider.getNetwork()).chainId, account),
            purpose: TransactionPurpose.CANCEL,
        };
    }
    createSeaportOrders(offerer, orderInputs) {
        const seaportLib = this.getSeaportLib();
        return seaportLib.createBulkOrders(orderInputs.map((orderInput) => {
            const { listingItem, considerationItem, orderStart, orderExpiry, } = orderInput;
            const offerItem = listingItem.type === 'ERC721'
                ? {
                    itemType: ItemType.ERC721,
                    token: listingItem.contractAddress,
                    identifier: listingItem.tokenId,
                }
                : {
                    itemType: ItemType.ERC1155,
                    token: listingItem.contractAddress,
                    identifier: listingItem.tokenId,
                    amount: listingItem.amount,
                };
            return {
                allowPartialFills: listingItem.type === 'ERC1155',
                offer: [offerItem],
                consideration: [
                    {
                        token: considerationItem.type === 'ERC20' ? considerationItem.contractAddress : undefined,
                        amount: considerationItem.amount,
                        recipient: offerer,
                    },
                ],
                startTime: (orderStart.getTime() / 1000).toFixed(0),
                endTime: (orderExpiry.getTime() / 1000).toFixed(0),
                zone: this.zoneContractAddress,
                restrictedByZone: true,
            };
        }), offerer);
    }
    createSeaportOrder(offerer, listingItem, considerationItem, orderStart, orderExpiry) {
        const seaportLib = this.getSeaportLib();
        const offerItem = listingItem.type === 'ERC721'
            ? {
                itemType: ItemType.ERC721,
                token: listingItem.contractAddress,
                identifier: listingItem.tokenId,
            }
            : {
                itemType: ItemType.ERC1155,
                token: listingItem.contractAddress,
                identifier: listingItem.tokenId,
                amount: listingItem.amount,
            };
        return seaportLib.createOrder({
            allowPartialFills: listingItem.type === 'ERC1155',
            offer: [offerItem],
            consideration: [
                {
                    token: considerationItem.type === 'ERC20' ? considerationItem.contractAddress : undefined,
                    amount: considerationItem.amount,
                    recipient: offerer,
                },
            ],
            startTime: (orderStart.getTime() / 1000).toFixed(0),
            endTime: (orderExpiry.getTime() / 1000).toFixed(0),
            zone: this.zoneContractAddress,
            restrictedByZone: true,
        }, offerer);
    }
    // Types and value are JSON parsed from the seaport-js string, so the types are
    // reflected as any
    async getTypedDataFromBulkOrderComponents(types, value) {
        // We must remove EIP712Domain from the types object
        // eslint-disable-next-line no-param-reassign
        delete types.EIP712Domain;
        const { chainId } = await this.provider.getNetwork();
        const domainData = {
            name: SEAPORT_CONTRACT_NAME,
            version: SEAPORT_CONTRACT_VERSION_V1_5,
            chainId,
            verifyingContract: this.seaportContractAddress,
        };
        return {
            domain: domainData,
            types,
            value,
        };
    }
    async getTypedDataFromOrderComponents(orderComponents) {
        const { chainId } = await this.provider.getNetwork();
        const domainData = {
            name: SEAPORT_CONTRACT_NAME,
            version: SEAPORT_CONTRACT_VERSION_V1_5,
            chainId,
            verifyingContract: this.seaportContractAddress,
        };
        return {
            domain: domainData,
            types: EIP_712_ORDER_TYPE,
            value: orderComponents,
        };
    }
    getSeaportLib(order) {
        const seaportAddress = order?.protocol_data?.seaport_address ?? this.seaportContractAddress;
        return this.seaportLibFactory.create(seaportAddress, this.rateLimitingKey);
    }
    static getExpirationISOTimeFromExtraData(extraData) {
        // Expirtaion bytes in SIP7 extra data [21:29]
        // In hex string -> [21 * 2 + 2 (0x) : 29 * 2]
        // In JS slice (start, end_inclusive), (44,60)
        // 8 bytes uint64 epoch time in seconds
        const expirationHex = extraData.slice(44, 60);
        const expirationInSeconds = parseInt(expirationHex, 16);
        return new Date(expirationInSeconds * 1000).toISOString();
    }
}

class ImmutableApiClient {
    orderbookService;
    chainName;
    seaportAddress;
    constructor(orderbookService, chainName, seaportAddress) {
        this.orderbookService = orderbookService;
        this.chainName = chainName;
        this.seaportAddress = seaportAddress;
    }
    async fulfillmentData(requests) {
        return this.orderbookService.fulfillmentData({
            chainName: this.chainName,
            requestBody: requests,
        });
    }
    async getListing(listingId) {
        return this.orderbookService.getListing({
            chainName: this.chainName,
            listingId,
        });
    }
    async getTrade(tradeId) {
        return this.orderbookService.getTrade({
            chainName: this.chainName,
            tradeId,
        });
    }
    async listListings(listOrderParams) {
        return this.orderbookService.listListings({
            chainName: this.chainName,
            ...listOrderParams,
        });
    }
    async listTrades(listTradesParams) {
        return this.orderbookService.listTrades({
            chainName: this.chainName,
            ...listTradesParams,
        });
    }
    async cancelOrders(orderIds, accountAddress, signature) {
        return this.orderbookService.cancelOrders({
            chainName: this.chainName,
            requestBody: {
                account_address: accountAddress,
                orders: orderIds,
                signature,
            },
        });
    }
    async createListing({ orderHash, orderComponents, orderSignature, makerFees, }) {
        if (orderComponents.offer.length !== 1) {
            throw new Error('Only one item can be listed at a time');
        }
        if (Number(orderComponents.offer[0].itemType) !== ItemType.ERC721
            && Number(orderComponents.offer[0].itemType) !== ItemType.ERC1155) {
            throw new Error('Only ERC721 / ERC1155 tokens can be listed');
        }
        const orderTypes = [
            ...orderComponents.consideration.map((c) => c.itemType),
        ];
        const isSameConsiderationType = new Set(orderTypes).size === 1;
        if (!isSameConsiderationType) {
            throw new Error('All consideration items must be of the same type');
        }
        return this.orderbookService.createListing({
            chainName: this.chainName,
            requestBody: {
                account_address: orderComponents.offerer,
                buy: [
                    {
                        type: Number(orderComponents.consideration[0].itemType)
                            === ItemType.NATIVE
                            ? 'NATIVE'
                            : 'ERC20',
                        amount: orderComponents.consideration[0].startAmount,
                        contract_address: orderComponents.consideration[0].token,
                    },
                ],
                fees: makerFees.map((x) => ({
                    amount: x.amount,
                    type: FeeType.MAKER_ECOSYSTEM,
                    recipient_address: x.recipientAddress,
                })),
                end_at: new Date(parseInt(`${orderComponents.endTime.toString()}000`, 10)).toISOString(),
                order_hash: orderHash,
                protocol_data: {
                    order_type: Number(orderComponents.offer[0].itemType) === ItemType.ERC1155
                        ? ProtocolData.order_type.PARTIAL_RESTRICTED : ProtocolData.order_type.FULL_RESTRICTED,
                    zone_address: orderComponents.zone,
                    seaport_address: this.seaportAddress,
                    seaport_version: SEAPORT_CONTRACT_VERSION_V1_5,
                    counter: orderComponents.counter.toString(),
                },
                salt: orderComponents.salt,
                sell: Number(orderComponents.offer[0].itemType) === ItemType.ERC1155
                    ? [{
                            contract_address: orderComponents.offer[0].token,
                            token_id: orderComponents.offer[0].identifierOrCriteria,
                            type: 'ERC1155',
                            amount: orderComponents.offer[0].startAmount,
                        }] : [{
                        contract_address: orderComponents.offer[0].token,
                        token_id: orderComponents.offer[0].identifierOrCriteria,
                        type: 'ERC721',
                    }],
                signature: orderSignature,
                start_at: new Date(parseInt(`${orderComponents.startTime.toString()}000`, 10)).toISOString(),
            },
        });
    }
}

class ImmutableApiClientFactory {
    chainName;
    seaportAddress;
    orderbookClient;
    constructor(apiEndpoint, chainName, seaportAddress, rateLimitingKey) {
        this.chainName = chainName;
        this.seaportAddress = seaportAddress;
        this.orderbookClient = new OrderBookClient({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            BASE: apiEndpoint,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            HEADERS: rateLimitingKey ? {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'x-api-key': rateLimitingKey,
            } : undefined,
        });
    }
    create() {
        return new ImmutableApiClient(this.orderbookClient.orders, this.chainName, this.seaportAddress);
    }
}

const TESTNET_CHAIN_NAME = 'imtbl-zkevm-testnet';
const MAINNET_CHAIN_NAME = 'imtbl-zkevm-mainnet';
function getConfiguredProvider(url, rateLimitingKey) {
    return new providers.JsonRpcProvider({
        url,
        headers: rateLimitingKey ? {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'x-api-key': rateLimitingKey,
        } : undefined,
    });
}
function getOrderbookConfig(config) {
    switch (config.baseConfig.environment) {
        case Environment.SANDBOX:
            return {
                seaportContractAddress: '0x7d117aA8BD6D31c4fa91722f246388f38ab1942c',
                zoneContractAddress: '0x1004f9615E79462c711Ff05a386BdbA91a7628C3', // ImmutableSignedZoneV2
                apiEndpoint: 'https://api.sandbox.immutable.com',
                chainName: TESTNET_CHAIN_NAME,
                provider: getConfiguredProvider('https://rpc.testnet.immutable.com', config.baseConfig.rateLimitingKey),
            };
        // not yet deployed
        case Environment.PRODUCTION:
            return {
                seaportContractAddress: '0x6c12aD6F0bD274191075Eb2E78D7dA5ba6453424',
                zoneContractAddress: '0x1004f9615E79462c711Ff05a386BdbA91a7628C3', // ImmutableSignedZoneV2
                apiEndpoint: 'https://api.immutable.com',
                chainName: MAINNET_CHAIN_NAME,
                provider: getConfiguredProvider('https://rpc.immutable.com', config.baseConfig.rateLimitingKey),
            };
        default:
            return null;
    }
}

// The order book module only supports V5 JsonRpcProviders. These are instantiated
// by the environment or by providing an RPC URL override. For this reason we can
// safely instantiate a V6 provider for the V5 provider URL.
function convertToV6Provider(provider, rateLimitingKey) {
    const fetch = new FetchRequest(provider.connection.url);
    if (rateLimitingKey) {
        fetch.setHeader('x-api-key', rateLimitingKey);
    }
    const overwrittenProvider = new JsonRpcProvider(fetch);
    // Need to override the getSigner method to mimic V5 behaviour
    overwrittenProvider.getSigner = async function getSigner(address) {
        if (address == null) {
            // eslint-disable-next-line no-param-reassign
            address = 0;
        }
        const accountsPromise = this.send('eth_accounts', []);
        // Account index
        if (typeof (address) === 'number') {
            const accounts = (await accountsPromise);
            if (address >= accounts.length) {
                throw new Error('no such account');
            }
            return new JsonRpcSigner(this, accounts[address]);
        }
        // Account address
        // This is where the override comes in to effect. We explicitly do not confirm if the
        // provider has access to the address as a signer.
        return new JsonRpcSigner(this, address);
    };
    return overwrittenProvider;
}
class SeaportLibFactory {
    defaultSeaportContractAddress;
    provider;
    constructor(defaultSeaportContractAddress, provider) {
        this.defaultSeaportContractAddress = defaultSeaportContractAddress;
        this.provider = provider;
    }
    create(orderSeaportAddress, rateLimitingKey) {
        const seaportContractAddress = orderSeaportAddress ?? this.defaultSeaportContractAddress;
        return new Seaport$1(convertToV6Provider(this.provider, rateLimitingKey), {
            balanceAndApprovalChecksOnOrderCreation: true,
            overrides: {
                contractAddress: seaportContractAddress,
            },
        });
    }
}

/**
 * zkEVM orderbook SDK
 * @constructor
 * @param {OrderbookModuleConfiguration} config - Configuration for Immutable services.
 */
class Orderbook {
    apiClient;
    seaport;
    orderbookConfig;
    constructor(config) {
        const obConfig = getOrderbookConfig(config);
        const finalConfig = {
            ...obConfig,
            ...config.overrides,
        };
        if (config.overrides?.jsonRpcProviderUrl) {
            finalConfig.provider = getConfiguredProvider(config.overrides?.jsonRpcProviderUrl, config.baseConfig.rateLimitingKey);
        }
        if (!finalConfig) {
            throw new Error('Orderbook configuration not passed, please specify the environment under config.baseConfig.environment');
        }
        this.orderbookConfig = finalConfig;
        const { apiEndpoint, chainName } = this.orderbookConfig;
        if (!apiEndpoint) {
            throw new Error('API endpoint must be provided');
        }
        this.apiClient = new ImmutableApiClientFactory(apiEndpoint, chainName, this.orderbookConfig.seaportContractAddress, config.baseConfig.rateLimitingKey).create();
        const seaportLibFactory = new SeaportLibFactory(this.orderbookConfig.seaportContractAddress, this.orderbookConfig.provider);
        this.seaport = new Seaport(seaportLibFactory, this.orderbookConfig.provider, this.orderbookConfig.seaportContractAddress, this.orderbookConfig.zoneContractAddress, config.baseConfig.rateLimitingKey);
    }
    // Default order expiry to 2 years from now
    static defaultOrderExpiry() {
        return new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 2);
    }
    /**
     * Return the configuration for the orderbook module.
     * @return {OrderbookModuleConfiguration} The configuration for the orderbook module.
     */
    config() {
        return this.orderbookConfig;
    }
    /**
     * Get an order by ID
     * @param {string} listingId - The listingId to find.
     * @return {ListingResult} The returned order result.
     */
    async getListing(listingId) {
        const apiListing = await this.apiClient.getListing(listingId);
        return {
            result: mapFromOpenApiOrder(apiListing.result),
        };
    }
    /**
     * Get a trade by ID
     * @param {string} tradeId - The tradeId to find.
     * @return {TradeResult} The returned order result.
     */
    async getTrade(tradeId) {
        const apiListing = await this.apiClient.getTrade(tradeId);
        return {
            result: mapFromOpenApiTrade(apiListing.result),
        };
    }
    /**
     * List orders. This method is used to get a list of orders filtered by conditions specified
     * in the params object.
     * @param {ListListingsParams} listOrderParams - Filtering, ordering and page parameters.
     * @return {ListListingsResult} The paged orders.
     */
    async listListings(listOrderParams) {
        const apiListings = await this.apiClient.listListings(listOrderParams);
        return {
            page: mapFromOpenApiPage(apiListings.page),
            result: apiListings.result.map(mapFromOpenApiOrder),
        };
    }
    /**
     * List trades. This method is used to get a list of trades filtered by conditions specified
     * in the params object
     * @param {ListTradesParams} listTradesParams - Filtering, ordering and page parameters.
     * @return {ListTradesResult} The paged trades.
     */
    async listTrades(listTradesParams) {
        const apiListings = await this.apiClient.listTrades(listTradesParams);
        return {
            page: mapFromOpenApiPage(apiListings.page),
            result: apiListings.result.map(mapFromOpenApiTrade),
        };
    }
    /**
     * Get required transactions and messages for signing to facilitate creating bulk listings.
     * Once the transactions are submitted and the message signed, call the completeListings method
     * provided in the return type with the signature. This method supports up to 20 listing creations
     * at a time. It can also be used for individual listings to simplify integration code paths.
     *
     * Bulk listings created using an EOA (Metamask) will require a single listing confirmation
     * signature.
     * Bulk listings creating using a smart contract wallet will require multiple listing confirmation
     * signatures(as many as the number of orders).
     * @param {PrepareBulkListingsParams} prepareBulkListingsParams - Details about the listings
     * to be created.
     * @return {PrepareBulkListingsResponse} PrepareListingResponse includes
     * any unsigned approval transactions, the typed bulk order message for signing and
     * the createListings method that can be called with the signature(s) to create the listings.
     */
    async prepareBulkListings({ makerAddress, listingParams, }) {
        // Limit bulk listing creation to 20 orders to prevent API and order evaluation spam
        if (listingParams.length > 20) {
            throw new Error('Bulk listing creation is limited to 20 orders');
        }
        // Bulk listings (with single listing) code path common for both Smart contract
        // wallets and EOAs.
        // In the event of a single order, delegate to prepareListing as the signature is more
        // gas efficient
        if (listingParams.length === 1) {
            const prepareListingResponse = await this.seaport.prepareSeaportOrder(makerAddress, listingParams[0].sell, listingParams[0].buy, new Date(), listingParams[0].orderExpiry || Orderbook.defaultOrderExpiry());
            return {
                actions: prepareListingResponse.actions,
                completeListings: async (signatures) => {
                    const createListingResult = await this.createListing({
                        makerFees: listingParams[0].makerFees,
                        orderComponents: prepareListingResponse.orderComponents,
                        orderHash: prepareListingResponse.orderHash,
                        orderSignature: typeof signatures === 'string' ? signatures : signatures[0],
                    });
                    return {
                        result: [{
                                success: true,
                                orderHash: prepareListingResponse.orderHash,
                                order: createListingResult.result,
                            }],
                    };
                },
            };
        }
        // Bulk listings (with multiple listings) code path for Smart contract wallets.
        // Code check to determine wallet type is not fool-proof but scenarios where smart
        // contract wallet is not deployed will be an edge case
        const isSmartContractWallet = await this.orderbookConfig.provider.getCode(makerAddress) !== '0x';
        if (isSmartContractWallet) {
            track('orderbookmr', 'bulkListings', { walletType: 'Passport', makerAddress, listingsCount: listingParams.length });
            // eslint-disable-next-line max-len
            const prepareListingResponses = await Promise.all(listingParams.map((listing) => this.seaport.prepareSeaportOrder(makerAddress, listing.sell, listing.buy, new Date(), listing.orderExpiry || Orderbook.defaultOrderExpiry())));
            const pendingApproval = [];
            const actions = prepareListingResponses.flatMap((response) => {
                // de-dupe approval transactions to ensure every contract has
                // a maximum of 1 approval transaction
                const dedupedActions = [];
                response.actions.forEach((action) => {
                    if (action.type === ActionType.TRANSACTION) {
                        // Assuming only a single item is on offer per listing
                        const contractAddress = response.orderComponents.offer[0].token;
                        if (!pendingApproval.includes(contractAddress)) {
                            pendingApproval.push(contractAddress);
                            dedupedActions.push(action);
                        }
                    }
                    else {
                        dedupedActions.push(action);
                    }
                });
                return dedupedActions;
            });
            return {
                actions,
                completeListings: async (signatures) => {
                    const signatureIsString = typeof signatures === 'string';
                    if (signatureIsString) {
                        throw new Error('A signature per listing must be provided for smart contract wallets');
                    }
                    const createListingsApiResponses = await Promise.all(prepareListingResponses.map((prepareListingResponse, i) => {
                        const signature = signatures[i];
                        return this.apiClient.createListing({
                            makerFees: listingParams[i].makerFees,
                            orderComponents: prepareListingResponse.orderComponents,
                            orderHash: prepareListingResponse.orderHash,
                            orderSignature: signature,
                            // Swallow failed creations,this gets mapped in the response to the caller as failed
                        }).catch(() => undefined);
                    }));
                    return {
                        result: createListingsApiResponses.map((apiListingResponse, i) => ({
                            success: !!apiListingResponse,
                            orderHash: prepareListingResponses[i].orderHash,
                            // eslint-disable-next-line max-len
                            order: apiListingResponse ? mapFromOpenApiOrder(apiListingResponse.result) : undefined,
                        })),
                    };
                },
            };
        }
        // Bulk listings (with multiple listings) code path for EOA wallets.
        track('orderbookmr', 'bulkListings', { walletType: 'EOA', makerAddress, listingsCount: listingParams.length });
        const { actions, preparedListings } = await this.seaport.prepareBulkSeaportOrders(makerAddress, listingParams.map((orderParam) => ({
            listingItem: orderParam.sell,
            considerationItem: orderParam.buy,
            orderStart: new Date(),
            orderExpiry: orderParam.orderExpiry || Orderbook.defaultOrderExpiry(),
        })));
        return {
            actions,
            completeListings: async (signatures) => {
                const signatureIsArray = typeof signatures === 'object';
                if (signatureIsArray && signatures.length !== 1) {
                    throw new Error('Only a single signature is expected for bulk listing creation');
                }
                const orderComponents = preparedListings.map((orderParam) => orderParam.orderComponents);
                const signature = signatureIsArray ? signatures[0] : signatures;
                const bulkOrderSignatures = getBulkSeaportOrderSignatures(signature, orderComponents);
                const createOrdersApiListingResponse = await Promise.all(orderComponents.map((orderComponent, i) => {
                    const sig = bulkOrderSignatures[i];
                    const listing = preparedListings[i];
                    const listingParam = listingParams[i];
                    return this.apiClient.createListing({
                        orderComponents: orderComponent,
                        orderHash: listing.orderHash,
                        orderSignature: sig,
                        makerFees: listingParam.makerFees,
                        // Swallow failed creations - this gets mapped in the response to the caller as failed
                    }).catch(() => undefined);
                }));
                return {
                    result: createOrdersApiListingResponse.map((apiListingResponse, i) => ({
                        success: !!apiListingResponse,
                        orderHash: preparedListings[i].orderHash,
                        order: apiListingResponse ? mapFromOpenApiOrder(apiListingResponse.result) : undefined,
                    })),
                };
            },
        };
    }
    /**
     * Get required transactions and messages for signing prior to creating a listing
     * through the createListing method
     * @param {PrepareListingParams} prepareListingParams - Details about the listing to be created.
     * @return {PrepareListingResponse} PrepareListingResponse includes
     * the unsigned approval transaction, the typed order message for signing and
     * the order components that can be submitted to `createListing` with a signature.
     */
    async prepareListing({ makerAddress, sell, buy, orderExpiry, }) {
        return this.seaport.prepareSeaportOrder(makerAddress, sell, buy, 
        // Default order start to now
        new Date(), 
        // Default order expiry to 2 years from now
        orderExpiry || Orderbook.defaultOrderExpiry());
    }
    /**
     * Create an order
     * @param {CreateListingParams} createListingParams - create an order with the given params.
     * @return {ListingResult} The result of the order created in the Immutable services.
     */
    async createListing(createListingParams) {
        const apiListingResponse = await this.apiClient.createListing({
            ...createListingParams,
        });
        return {
            result: mapFromOpenApiOrder(apiListingResponse.result),
        };
    }
    /**
     * Get unsigned transactions that can be submitted to fulfil an open order. If the approval
     * transaction exists it must be signed and submitted to the chain before the fulfilment
     * transaction can be submitted or it will be reverted.
     * @param {string} listingId - The listingId to fulfil.
     * @param {string} takerAddress - The address of the account fulfilling the order.
     * @param {FeeValue[]} takerFees - Taker ecosystem fees to be paid.
     * @param {string} amountToFill - Amount of the order to fill, defaults to sell item amount.
     *                                Only applies to ERC1155 orders
     * @return {FulfillOrderResponse} Approval and fulfilment transactions.
     */
    async fulfillOrder(listingId, takerAddress, takerFees, amountToFill) {
        const fulfillmentDataRes = await this.apiClient.fulfillmentData([
            {
                order_id: listingId,
                taker_address: takerAddress,
                fees: takerFees.map((fee) => ({
                    amount: fee.amount,
                    type: FeeType.TAKER_ECOSYSTEM,
                    recipient_address: fee.recipientAddress,
                })),
            },
        ]);
        if (fulfillmentDataRes.result.unfulfillable_orders?.length > 0) {
            throw new Error(`Unable to prepare fulfillment date: ${fulfillmentDataRes.result.unfulfillable_orders[0].reason}`);
        }
        else if (fulfillmentDataRes.result.fulfillable_orders?.length !== 1) {
            throw new Error('unexpected fulfillable order result length');
        }
        const extraData = fulfillmentDataRes.result.fulfillable_orders[0].extra_data;
        const orderResult = fulfillmentDataRes.result.fulfillable_orders[0].order;
        if (orderResult.status.name !== OrderStatusName.ACTIVE) {
            throw new Error(`Cannot fulfil order that is not active. Current status: ${orderResult.status}`);
        }
        return this.seaport.fulfillOrder(orderResult, takerAddress, extraData, amountToFill);
    }
    /**
     * Get unsigned transactions that can be submitted to fulfil multiple open orders. If approval
     * transactions exist, they must be signed and submitted to the chain before the fulfilment
     * transaction can be submitted or it will be reverted.
     * @param {Array<FulfillmentListing>} listings - The details of the listings to fulfil, amounts
     *                                               to fill and taker ecosystem fees to be paid.
     * @param {string} takerAddress - The address of the account fulfilling the order.
     * @return {FulfillBulkOrdersResponse} Approval and fulfilment transactions.
     */
    async fulfillBulkOrders(listings, takerAddress) {
        const fulfillmentDataRes = await this.apiClient.fulfillmentData(listings.map((listingRequest) => ({
            order_id: listingRequest.listingId,
            taker_address: takerAddress,
            fees: listingRequest.takerFees.map((fee) => ({
                amount: fee.amount,
                type: FeeType.TAKER_ECOSYSTEM,
                recipient_address: fee.recipientAddress,
            })),
        })));
        try {
            const fulfillableOrdersWithUnits = fulfillmentDataRes.result.fulfillable_orders
                .map((fulfillmentData) => {
                // Find the listing that corresponds to the order for the units
                const listing = listings.find((l) => l.listingId === fulfillmentData.order.id);
                if (!listing) {
                    throw new Error(`Could not find listing for order ${fulfillmentData.order.id}`);
                }
                return {
                    extraData: fulfillmentData.extra_data,
                    order: fulfillmentData.order,
                    unitsToFill: listing.amountToFill,
                };
            });
            return {
                ...(await this.seaport.fulfillBulkOrders(fulfillableOrdersWithUnits, takerAddress)),
                fulfillableOrders: fulfillmentDataRes.result.fulfillable_orders.map((o) => mapFromOpenApiOrder(o.order)),
                unfulfillableOrders: fulfillmentDataRes.result.unfulfillable_orders.map((o) => ({
                    orderId: o.order_id,
                    reason: o.reason,
                })),
                sufficientBalance: true,
            };
        }
        catch (e) {
            // if insufficient balance error, we return FulfillBulkOrdersInsufficientBalanceResponse
            if (String(e).includes('The fulfiller does not have the balances needed to fulfill.')) {
                return {
                    fulfillableOrders: fulfillmentDataRes.result.fulfillable_orders.map((o) => mapFromOpenApiOrder(o.order)),
                    unfulfillableOrders: fulfillmentDataRes.result.unfulfillable_orders.map((o) => ({
                        orderId: o.order_id,
                        reason: o.reason,
                    })),
                    sufficientBalance: false,
                };
            }
            // if some other error is thrown,
            // there likely is a race condition of the original order validity
            // we throw the error back out
            throw e;
        }
    }
    /**
     * Cancelling orders is a gasless alternative to on-chain cancellation exposed with
     * `cancelOrdersOnChain`. For the orderbook to authenticate the cancellation, the creator
     * of the orders must sign an EIP712 message containing the orderIds
     * @param {string} orderIds - The orderIds to attempt to cancel.
     * @return {PrepareCancelOrdersResponse} The signable action to cancel the orders.
     */
    async prepareOrderCancellations(orderIds) {
        const network = await this.orderbookConfig.provider.getNetwork();
        const domain = {
            name: 'imtbl-order-book',
            chainId: network.chainId,
            verifyingContract: this.orderbookConfig.seaportContractAddress,
        };
        const types = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            CancelPayload: [
                { name: 'orders', type: 'Order[]' },
            ],
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Order: [
                { name: 'id', type: 'string' },
            ],
        };
        const cancelMessage = {
            orders: orderIds.map((id) => ({ id })),
        };
        return {
            signableAction: {
                purpose: SignablePurpose.OFF_CHAIN_CANCELLATION,
                type: ActionType.SIGNABLE,
                message: {
                    domain,
                    types,
                    value: cancelMessage,
                },
            },
        };
    }
    /**
     * Cancelling orders is a gasless alternative to on-chain cancellation exposed with
     * `cancelOrdersOnChain`. Orders cancelled this way cannot be fulfilled and will be removed
     * from the orderbook. If there is pending fulfillment data outstanding for the order, its
     * cancellation will be pending until the fulfillment window has passed.
     * `prepareOffchainOrderCancellations` can be used to get the signable action that is signed
     * to get the signature required for this call.
     * @param {string[]} orderIds - The orderIds to attempt to cancel.
     * @param {string} accountAddress - The address of the account cancelling the orders.
     * @param {string} accountAddress - The address of the account cancelling the orders.
     * @return {CancelOrdersResult} The result of the off-chain cancellation request
     */
    async cancelOrders(orderIds, accountAddress, signature) {
        return this.apiClient.cancelOrders(orderIds, accountAddress, signature);
    }
    /**
     * Get an unsigned order cancellation transaction. Orders can only be cancelled by
     * the account that created them. All of the orders must be from the same seaport contract.
     * If trying to cancel orders from multiple seaport contracts, group the orderIds by seaport
     * contract and call this method for each group.
     * @param {string[]} orderIds - The orderIds to cancel.
     * @param {string} accountAddress - The address of the account cancelling the order.
     * @return {CancelOrdersOnChainResponse} The unsigned cancel order action
     */
    async cancelOrdersOnChain(orderIds, accountAddress) {
        const orderResults = await Promise.all(orderIds.map((id) => this.apiClient.getListing(id)));
        // eslint-disable-next-line no-restricted-syntax
        for (const orderResult of orderResults) {
            if (orderResult.result.status.name !== OrderStatusName.ACTIVE
                && orderResult.result.status.name !== OrderStatusName.INACTIVE
                && orderResult.result.status.name !== OrderStatusName.PENDING) {
                throw new Error(`Cannot cancel order with status ${orderResult.result.status}`);
            }
            if (orderResult.result.account_address !== accountAddress.toLowerCase()) {
                throw new Error(`Only account ${orderResult.result.account_address} can cancel order ${orderResult.result.id}`);
            }
        }
        const orders = orderResults.map((orderResult) => orderResult.result);
        const seaportAddresses = orders.map((o) => o.protocol_data.seaport_address);
        const distinctSeaportAddresses = new Set(...[seaportAddresses]);
        if (distinctSeaportAddresses.size !== 1) {
            throw new Error('Cannot cancel multiple orders from different seaport contracts. Please group your orderIds accordingly');
        }
        const cancellationAction = await this.seaport.cancelOrders(orders, accountAddress);
        return { cancellationAction };
    }
}

// Consumers might want an estimated gas limit for fulfilling an order
// without calling the transaction builder. This is an estimate that
// should work for all fulfillment scenarios.
const ESTIMATED_FULFILLMENT_GAS_GWEI = 400_000;
const constants = {
    estimatedFulfillmentGasGwei: ESTIMATED_FULFILLMENT_GAS_GWEI,
};

export { ActionType, FeeType, OrderStatusName, Orderbook, SignablePurpose, TransactionPurpose, constants };
