import { setEnvironment, track, setPublishableApiKey } from '@imtbl/metrics';

var Environment;
(function (Environment) {
    Environment["PRODUCTION"] = "production";
    Environment["SANDBOX"] = "sandbox";
})(Environment || (Environment = {}));
var KeyHeaders;
(function (KeyHeaders) {
    KeyHeaders["API_KEY"] = "x-immutable-api-key";
    KeyHeaders["PUBLISHABLE_KEY"] = "x-immutable-publishable-key";
    KeyHeaders["RATE_LIMITING_KEY"] = "x-api-key";
})(KeyHeaders || (KeyHeaders = {}));
class ImmutableConfiguration {
    environment;
    rateLimitingKey;
    apiKey;
    publishableKey;
    constructor(options) {
        this.environment = options.environment;
        this.publishableKey = options.publishableKey;
        this.apiKey = options.apiKey;
        this.rateLimitingKey = options.rateLimitingKey;
        setEnvironment(options.environment);
        track('config', 'created_imtbl_config');
    }
}
// Adds publishableKey, apiKey, and rateLimitingKey to the headers of the overrides object
// if exists in base config. Otherwise returns the overrides object as is.
// Use this for openapi generated clients with security headers.
const addKeysToHeadersOverride = (baseConfig, overrides) => {
    if (!baseConfig || (!baseConfig.apiKey && !baseConfig.publishableKey && !baseConfig.rateLimitingKey)) {
        return overrides;
    }
    const newHeaders = {};
    if (baseConfig.apiKey) {
        newHeaders[KeyHeaders.API_KEY] = baseConfig.apiKey;
    }
    if (baseConfig.publishableKey) {
        newHeaders[KeyHeaders.PUBLISHABLE_KEY] = baseConfig.publishableKey;
        setPublishableApiKey(baseConfig.publishableKey);
    }
    if (baseConfig.rateLimitingKey) {
        newHeaders[KeyHeaders.RATE_LIMITING_KEY] = baseConfig.rateLimitingKey;
    }
    // If overrides and overrides.headers exist, merge them with newHeaders, giving precedence to existing overrides
    if (overrides && overrides.headers) {
        return {
            ...overrides,
            headers: {
                ...newHeaders, // Add newHeaders first so that the existing keys in overrides.headers can override them
                ...overrides.headers,
            },
        };
    }
    return {
        ...overrides,
        headers: newHeaders,
    };
};

export { Environment, ImmutableConfiguration, KeyHeaders, addKeysToHeadersOverride };
